import axios from "axios";

const baseUrl = "https://solo-dev-zero.lol:3000";

/**
 * Get all network test accounts by user id
 *
 * @param {Number} userId - User ID
 * @returns {Promise<Object>} - The response data from the server.
 */
export const getAccountsByUserId = async (userId, token) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/network-test`, // Replace with your backend endpoint
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          userId: userId,
          sortBy: "loyaltyPoints",
          order: "desc",
        },
      }
    );

    return {
      responseData: response.data,
      statusCode: response.status,
    };
  } catch (error) {
    return {
      responseData: error.response?.data || error.message,
      statusCode: error.status,
    };
  }
};

/**
 * Request an OTP to link with other number
 */
export const requestLinkOtherOTP = async (
  userId,
  token,
  accountId,
  otherNumber
) => {
  try {
    const response = await axios.post(
      `${baseUrl}/api/link-other/request`,
      {
        accountId: accountId,
        otherNumber: otherNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          userId: userId,
        },
      }
    );

    return {
      responseData: response.data,
      statusCode: response.status,
    };
  } catch (error) {
    return {
      responseData: error.response?.data || error.message,
      statusCode: error.status,
    };
  }
};

/**
 * Request an OTP to link with other number
 */
export const verifyLinkOtherOTP = async (
  userId,
  token,
  accountId,
  transactionId,
  otpCode
) => {
  try {
    const response = await axios.post(
      `${baseUrl}/api/link-other/verify`,
      {
        accountId: accountId,
        transactionId: transactionId,
        otpCode: otpCode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          userId: userId,
        },
      }
    );

    return {
      responseData: response.data,
      statusCode: response.status,
    };
  } catch (error) {
    return {
      responseData: error.response?.data || error.message,
      statusCode: error.status,
    };
  }
};
