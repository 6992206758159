import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-blue-50">
      <div className="text-center p-8 max-w-md">
        <h1 className="text-6xl font-bold text-blue-600">404</h1>
        <p className="text-lg text-gray-700 mt-4">
          Oops! The page you're looking for doesn't exist.
        </p>
        <p className="text-sm text-gray-500 mt-2">
          It seems like you've hit a broken link or the page has been moved.
        </p>
        <div className="mt-6">
          <a
            href="/"
            className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-200"
          >
            Go Back to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
