import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useState } from "react";
import { login } from "../api/auth";

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");

    const resp = await login({ username: username, password: password });

    if (resp.statusCode === 200) {
      setToken(resp.responseData.token);
      localStorage.setItem("user_id", resp.responseData.id);
      navigate("/", { replace: true });
    } else {
      // request failed
      setErrorMessage(resp.responseData);
    }

    setLoading(false);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-light-blue-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-3xl font-semibold text-center text-light-blue-700 mb-6">
          Welcome Back
        </h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label
              className="block text-light-blue-700 font-medium mb-2"
              htmlFor="username"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-4 py-2 border rounded-lg text-light-blue-700 focus:outline-none focus:ring-2 focus:ring-light-blue-500"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              className="block text-light-blue-700 font-medium mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-4 py-2 border rounded-lg text-light-blue-700 focus:outline-none focus:ring-2 focus:ring-light-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {errorMessage && (
            <div className="py-3 px-4 bg-red-500 text-white rounded-lg">
              <strong>Error:</strong> {errorMessage}
            </div>
          )}
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-light-blue-500 text-white font-bold py-3 px-4 rounded-lg hover:bg-light-blue-600 focus:outline-none focus:ring-2 focus:ring-light-blue-300 flex justify-center items-center"
          >
            {loading ? (
              <div className="w-6 h-6 border-4 border-t-white border-blue-500 rounded-full animate-spin"></div>
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
