import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAccountsByUserId,
  requestLinkOtherOTP,
  verifyLinkOtherOTP,
} from "../api/networkAccounts";
import { useAuth } from "../provider/authProvider";
import { MaterialReactTable } from "material-react-table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles
import { ClipLoader } from "react-spinners"; // Importing a spinner from react-spinners

const AccountsPage = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [rowStates, setRowStates] = useState({}); // Track state for each row by ID

  const [isModalOpen, setIsModalOpen] = useState(false); // Logout confirmation model

  const confirmLogout = () => {
    setIsModalOpen(false); // Close the modal
    clearCredentials();
    navigate("/", { replace: true });
  };

  function clearCredentials() {
    setToken();
    localStorage.removeItem("user_id");
  }

  // Getting all accounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await getAccountsByUserId(userId, token);

      if (resp.statusCode === 200) {
        setAccounts(resp.responseData);
      } else if (resp.statusCode === 401) {
        clearCredentials();
        navigate("/", { replace: true });
      } else {
        // request failed
        setError(resp.responseData);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  // Columns
  const columns = [
    {
      header: "No",
      id: "serial",
      // Use row index to generate serial numbers dynamically
      Cell: ({ row }) => row.index + 1, // Serial number starts from 1
      size: 100, // Adjust the width of the serial column as needed
    },
    // { accessorKey: "id", header: "ID" },
    { accessorKey: "phoneNumber", header: "Phone Number" },
    { accessorKey: "loyaltyPoints", header: "Loyalty Points" },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const rowId = row.original.id;
        const rowState = rowStates[rowId] || {
          otpRequested: false,
          isLoading: false,
          inputPlaceholder: "Enter other number",
          inputValue: "",
          transactionId: "",
        };

        const handleButtonClick = async () => {
          setRowStates((prev) => ({
            ...prev,
            [rowId]: { ...rowState, isLoading: true },
          }));

          try {
            if (!rowState.otpRequested) {
              // Request Link Other OTP
              const resp = await requestLinkOtherOTP(
                userId,
                token,
                row.original.id,
                rowState.inputValue
              );
              if (resp.statusCode !== 200) {
                setRowStates((prev) => ({
                  ...prev,
                  [rowId]: {
                    ...prev[rowId],
                    isLoading: false,
                  },
                }));
                toast.error(resp.responseData.message || "Unknown Error");
                return;
              }
              const transactionId = resp.responseData.transactionId;

              // If input is not shown, show it
              setRowStates((prev) => ({
                ...prev,
                [rowId]: {
                  ...prev[rowId],
                  otpRequested: true,
                  isLoading: false,
                  inputPlaceholder: "Enter the OTP",
                  inputValue: "",
                  transactionId: transactionId,
                },
              }));
              toast.info("Please enter the OTP to confirm.");
            } else {
              // Verify Link Other OTP
              const resp = await verifyLinkOtherOTP(
                userId,
                token,
                row.original.id,
                rowState.transactionId,
                rowState.inputValue
              );
              if (resp.statusCode !== 200) {
                setRowStates((prev) => ({
                  ...prev,
                  [rowId]: {
                    ...prev[rowId],
                    isLoading: false,
                    otpRequested: false,
                    inputPlaceholder: "Enter other number",
                    inputValue: "",
                    transactionId: "",
                  },
                }));
                toast.error(resp.responseData.message || "Unknown Error");
                return;
              }

              // If otp is already requested, make another API call and hide input
              toast.success(`Success: ${resp.responseData.message}`);
              setRowStates((prev) => ({
                ...prev,
                [rowId]: {
                  otpRequested: false,
                  isLoading: false,
                  inputPlaceholder: "Enter other number",
                  inputValue: "",
                },
              }));
            }
          } catch (error) {
            setRowStates((prev) => ({
              ...prev,
              [rowId]: { ...rowState, isLoading: false },
            }));
            toast.error("Failed to link.");
          }
        };

        const handleInputChange = (e) => {
          const value = e.target.value;
          setRowStates((prev) => ({
            ...prev,
            [rowId]: { ...rowState, inputValue: value },
          }));
        };

        return (
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={rowState.inputValue}
              onChange={handleInputChange}
              className="border border-gray-300 rounded px-2 py-1"
              placeholder={rowState.inputPlaceholder}
            />
            <button
              onClick={handleButtonClick}
              disabled={rowState.isLoading}
              className={`px-4 py-2 text-sm text-white ${
                rowState.isLoading
                  ? "bg-gray-400"
                  : "bg-blue-500 hover:bg-blue-600"
              } rounded transition-all duration-300`}
            >
              {rowState.isLoading ? (
                <div className="w-4 h-4 border-2 border-t-white border-blue-300 rounded-full animate-spin"></div>
              ) : rowState.otpRequested ? (
                "Confirm"
              ) : (
                "Link"
              )}
            </button>
          </div>
        );
      },
    },
  ];

  if (loading)
    return (
      <div className="flex flex-col items-center justify-center space-y-4 min-h-screen">
        <ClipLoader size={50} color={"#3498db"} loading={loading} />
        <p className="text-lg text-blue-600 font-semibold animate-pulse">
          We are fetching your data...
        </p>
      </div>
    );

  if (error)
    return (
      <div className="flex flex-col items-center space-y-4 text-center justify-center min-h-screen">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 text-red-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="8" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
        <p className="text-xl text-red-600 font-semibold">
          Oops! Something went wrong.
        </p>
        <p className="text-md text-gray-600">Error: {error}</p>
        <button
          className="bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-500 transition-all duration-300"
          onClick={() => window.location.reload()} // Reload page to retry
        >
          Try Again
        </button>
      </div>
    );

  return (
    <>
      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-bold text-gray-800">Confirm Logout</h2>
            <p className="text-gray-600 mt-2">
              Are you sure you want to log out?
            </p>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
              >
                Cancel
              </button>
              <button
                onClick={confirmLogout}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}

      <MaterialReactTable
        columns={columns}
        data={accounts}
        enableSorting
        enablePagination
        enableFullScreenToggle={false}
        renderTopToolbarCustomActions={() => (
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-4 py-2 bg-red-500 text-white font-medium rounded-lg hover:bg-red-600 transition duration-200"
          >
            Logout
          </button>
        )}
      />

      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default AccountsPage;
