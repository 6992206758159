import axios from "axios";

/**
 * Logs in the user by making a POST request with credentials.
 * Stores the JWT in localStorage if not using HTTP-only cookies.
 *
 * @param {Object} credentials - User login credentials (username and password).
 * @returns {Promise<Object>} - The response data from the server.
 */
export const login = async (credentials) => {
  try {
    const response = await axios.post(
      "https://solo-dev-zero.lol:3000/api/auth/login", // Replace with your backend endpoint
      {
        username: credentials.username,
        password: credentials.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Optionally store JWT if not using HTTP-only cookies
    // localStorage.setItem('token', response.data.token);

    return {
      responseData: response.data,
      statusCode: response.status,
    };
  } catch (error) {
    return {
      responseData: error.response?.data || error.message,
      statusCode: error.status,
    };
  }
};

/**
 * Logs out the user by making a POST or GET request to the logout endpoint.
 * Removes the JWT from localStorage if manually stored.
 *
 * @returns {Promise<void>} - Resolves if the logout is successful.
 */
export const logout = async () => {
  try {
    await axios.post(
      "/api/logout", // Replace with your backend logout endpoint
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Required for HTTP-only cookies
      }
    );

    // Optionally clear JWT from localStorage
    // localStorage.removeItem('jwt');

    console.log("Logout successful");
  } catch (error) {
    console.error("Logout failed:", error.response?.data || error.message);
    throw error;
  }
};
